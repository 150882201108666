<template>
  <div>
    <base-section id="serviceprovidermarket-account">
      <v-container>
        <v-row justify="space-between">
          <v-col cols="12" md="12">
            <base-info-card :title="$t('marketSP.mainTitle')" />
          </v-col>
        </v-row>
        <p />
        <v-row>
          {{ $t("marketSP.line1") }}
        </v-row>
        <v-row>
          <!----  We are displaying the basic information before the search  ---------------------------------->
          <v-col cols="12" sm="3" md="2">
            <kdw-text-field
              v-if="
                serviceProvider !== null &&
                serviceProvider.postcodecitycpy !== null
              "
              v-model="serviceProvider.postcodecitycpy.postalCode"
              :disabled="true"
              :label="$t('hero.postalCode')"
              prepend-icon="mdi-map-marker"
            />
          </v-col>
          <v-col cols="12" sm="3" md="4">
            <kdw-text-field
              v-if="
                serviceProvider !== null &&
                serviceProvider.postcodecitycpy !== null
              "
              v-model="serviceProvider.postcodecitycpy.townName"
              :disabled="true"
              :label="$t('hero.city')"
              prepend-icon="mdi-map-marker"
            />
          </v-col>
          <v-col cols="12" sm="3" md="2">
            <kdw-text-field
              v-model="parameters.searchRadius.radius"
              type="number"
              :label="$t('hero.radius')"
              prepend-icon="mdi-map-marker-radius"
              hint="KM"
            />
          </v-col>
          <v-col cols="12" sm="3" md="2">
            <kdw-btn
              v-if="
                serviceProvider !== null &&
                serviceProvider.postcodecitycpy !== null
              "
              id="searchSPMarket"
              @click="search"
            >
              {{ $t("hero.searchBtn") }}
            </kdw-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-list-item v-if="listCategorySP.length > 0">
            <v-list-item-content>
              <v-list-item-title style="white-space: normal">
                {{ $t("marketSP.categoryList") }}:
              </v-list-item-title>
              <v-list-item-subtitle style="white-space: normal">
                {{ listCategorySPText }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              v-if="listCategorySP.length === 0"
              v-model="selectedCategory"
              class="text-field-single-line-label-top text-field-single-line-prepend-append-margin-top"
              :items="listAllCategory"
              item-text="name"
              item-value="id"
              dense
              prepend-icon="mdi-folder-pound"
              :label="$t('hero.categoryTable')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <kdw-market-search-results
              :search-event="searchResultFlag"
              :localisation="localisation"
              :list-category="listCategorySP"
              @ResetSearchEvent="resetSearchEvent()"
            />
            <!-- @alertSnackBarMessage="alertSnackBarMessage($event)" -->
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </div>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
import { Logger } from "aws-amplify";
import { loadServiceProviderParameters } from "@/services/Parameters";
// import { requestfromOnePostalCodeRayon } from '@/services/Distance'
import { fetchCategoryListForSP, fetchCategory } from "@/services/Category";
import { provideExpirationDate } from "@/helpers/GenericFunctions";
// import { RequestStateMachine } from '@/services/StateMachine'
import { RequestService } from "@/services/Request";
// import { fetchServicesSimple, fetchLegalAndCustomerNote } from '@/services/Service'
// import { navigate } from '@/helpers/Navigation'
import { checkSPConsent } from "@/services/Consent";
import i18n from "@/plugins/i18n.js";

const logger = new Logger("ServiceProviderMarketAccount");

@Component({ name: "ServiceProviderMarketAccount" })
class ServiceProviderMarketAccount extends Vue {
  consentOK = "";
  parameters = { searchRadius: { radius: 10 }, subscription: { type: "None" } };
  searchResult = "";
  serviceProvider = null;
  listCategorySP = [];
  listCategorySPText = "";
  listAllCategory = [];
  selectedCategory = "";
  request = null;
  searchTable = "";
  serviceList = [];
  selectedService = "";

  deleteItem = [];
  editedIndex = -1;
  editedItem = {
    name: "",
  };

  defaultItem = {
    name: "",
  };

  myAnswer = {
    servicePrice: 0,
    dateExpiry: provideExpirationDate().substr(0, 10),
    serviceLegaldescription: "",
    noteCustomer: "",
    notePersonal: "",
    quotation: "",
    quotationFile: null,
  };

  myAnswerDefault = {
    servicePrice: 0,
    dateExpiry: provideExpirationDate().substr(0, 10),
    serviceLegaldescription: "",
    noteCustomer: "",
    notePersonal: "",
    quotation: "",
    quotationUrl: "",
    quotationFile: null,
  };

  // New variable to manage the new design:
  searchResultFlag = false;
  localisation = {};

  created() {
    // console.log('created')
    this.request = new RequestService();
    checkSPConsent().then((result) => {
      this.consentOK = result;
      loadServiceProviderParameters()
        .then((result) => {
          // console.log('result:')
          // console.log(result)
          if (result !== false) {
            this.serviceProvider = Object.assign({}, result);
            if (result.parameters !== undefined) {
              const parametersLoaded = JSON.parse(result.parameters);
              // console.log('parameters:')
              // console.log(result.parameters)
              this.parameters.searchRadius.radius =
                parametersLoaded.searchRadius.radius;
              if (this.serviceProvider.postcodecitycpy === null) {
                this.searchResult = this.$i18n.t(
                  "marketSP.alertSPPostcodeNotDefined",
                );
              }
            }
          }
          fetchCategoryListForSP(this.listCategorySP)
            .then(() => {
              // console.log(this.listCategorySP)
              if (this.listCategorySP.length === 0) {
                // console.log('we could not find anything for the SP')
                // We could not find any service, so we are fetch all categories
                fetchCategory(this.listAllCategory)
                  .then(() => {
                    // console.log('this.listAllCategory:')
                    // console.log(this.listAllCategory)
                    return true;
                  })
                  .catch((err) => logger.error(err));
              } else {
                this.listCategorySPText = "";
                var categoryName = "";
                for (var k = 0; k < this.listCategorySP.length; k++) {
                  if (this.listCategorySP[k].nameTag !== "") {
                    categoryName = i18n.t(this.listCategorySP[k].nameTag);
                  } else {
                    categoryName = this.listCategorySP[k].name;
                  }
                  // this.listCategorySPText = this.listCategorySPText + i18n.t(this.listCategorySP[k].nameTag) + ', '
                  this.listCategorySPText =
                    this.listCategorySPText + categoryName + ", ";
                }
              }
              return true;
            })
            .catch((err) => logger.error(err));
        })
        .catch((err) => logger.error(err));
    });
  }

  search() {
    // console.log('search...')
    // console.log(this.serviceProvider)
    if (this.consentOK !== "") {
      this.$root.kdwsnackbar.alert(this.consentOK, -1);
      return;
    }
    this.searchResult = "";
    if (
      this.serviceProvider.postcodecitycpy === undefined ||
      this.serviceProvider.postcodecitycpy === ""
    ) {
      this.$root.kdwsnackbar.alert("marketSP.alertSPPostcodeNotDefined");
    }
    this.searchResultFlag = true;
    this.localisation = {
      latitude: this.serviceProvider.postcodecitycpy.latitude,
      longitude: this.serviceProvider.postcodecitycpy.longitude,
      radius: this.parameters.searchRadius.radius,
    };
    // console.log('this.listCategorySP before..')
    // console.log(this.listCategorySP)
  }

  getSearchBtnDisabled() {
    var value = false;
    if (this.serviceProvider.postcodecitycpy === null) {
      value = true;
    }
    return value;
  }

  // NEW FUNCTIONS IMPLEMEBNTED
  resetSearchEvent() {
    this.searchResultFlag = false;
  }
  /*
    alertSnackBarMessage (event) {
      this.$root.kdwsnackbar.alert(event.message, event.snackbarTimeout)
    }
    */
}

export default ServiceProviderMarketAccount;
</script>
